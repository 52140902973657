<template>
  <div class="form-layout">
    <slot name="top" />
    <div class="login-form">
      <BaseElForm
        ref="formRef"
        class="login-form-container"
        label-position="top"
        :model="authFormData"
        :rules="formRules"
      >
        <div class="flex justify-between">
          <h1 v-if="['righttime', 'wishmobile', 'xuanien'].includes(appTheme)" class="login-form__login">LOGIN</h1>
          <h1 v-else class="login-form__login view-title">{{ $t('login.title') }}</h1>
          <div class="locale-changer">
            <el-select v-model="selectedLang">
              <el-option
                v-for="(lang, i) in langs"
                :key="`Lang${i}`"
                :label="lang.label"
                :value="lang.value"
              />
            </el-select>
          </div>
        </div>
        <BaseElFormItem prop="orgCode" :label="$t('login.group.title')">
          <OrgSearch data-testid="org-select" class="form__input__org" :model.sync="queryOrg" :disabled="!!subdomainCode" @change="changeOrg" />
          <img v-if="['righttime', 'wishmobile', 'xuanien'].includes(appTheme)" class="prefix__icon" :src="iconOrg" alt="organization icon">
        </BaseElFormItem>
        <BaseElFormItem prop="email" :label="$t('login.email.title')">
          <BaseElInput v-model="authFormData.email" data-testid="email-input" class="form__input__email" :placeholder="$t('login.email.placeholder')" @keyup.enter.native="handleLogin" />
          <img v-if="['righttime', 'wishmobile', 'xuanien'].includes(appTheme)" class="prefix__icon" :src="iconEmail" alt="email icon">
        </BaseElFormItem>
        <BaseElFormItem class="login-form__password w-full" prop="password" :label="$t('login.password.title')">
          <template #label>
            <div class="inline-flex justify-between w-full">
              <p>{{ $t('login.password.title') }}</p>
              <p class="underline cursor-pointer text-sub text-gray-60" @click="onForgetPassword">{{ $t('common.button.forgetPassword.text') }}</p>
            </div>
          </template>
          <BaseElInput
            v-model="authFormData.password"
            data-testid="password-input"
            class="form__input__password"
            show-password
            :placeholder="$t('login.password.placeholder')"
            @keyup.enter.native="handleLogin"
          />
          <img v-if="['righttime', 'wishmobile', 'xuanien'].includes(appTheme)" class="prefix__icon" :src="iconPassword" alt="password icon">
        </BaseElFormItem>
        <BaseElFormItem prop="agreeTerms" class="login-form__agreecheck">
          <BaseElCheckbox data-testid="agree-terms-checkbox" :value="authFormData.agreeTerms" @change="handleChangeTerms">
            {{ $t('login.agree.title') }}
            <span class="agree-terms" data-testid="show-terms-btn" @click.exact="handleClickTerms">《{{ $t('login.loginTerms.title') }}》。</span>
          </BaseElCheckbox>
        </BaseElFormItem>
        <BaseElFormItem>
          <BaseElButton
            data-testid="login-btn"
            type="primary"
            :loading="loading.login"
            @click="handleLogin"
          >
            {{ $t('login.button.login.text') }}
          </BaseElButton>
        </BaseElFormItem>
      </BaseElForm>
    </div>
    <p class="copyright">
      © {{ year }} {{ copyRightSite }} <span class="version">{{ $t('login.currentVersion.title') }}v{{ version }}</span>
    </p>

    <GRecaptchaV2Modal
      v-if="showV2Recaptcha"
      @close="onCloseRecaptchaV2"
      @verify="v2RecaptchaVerify($event, handleLogin)"
    />
  </div>
</template>

<script>
import dayjs from '@/lib/dayjs'
import ls from '@/utils/localstorage'
import formUtils from '@/utils/form'
import OrgSearch from '@/components/Search/OrgSearch.vue'
import { EmailLogin, GetOrgsByCode } from '@/api/auth'
import { noEmptyRules, changeEventRules, minLengthRules, atLeastOneUpperCaseRules, atLeastOneLowerCaseRules, atLeastOneNumberRules, atLeastOneSpecialCharRules } from '@/validation'
import iconOrg from '@/assets/login/right-time/icon-org.svg'
import iconEmail from '@/assets/login/right-time/icon-email.svg'
import iconPassword from '@/assets/login/right-time/icon-password.svg'
import { getAppVersion } from '@/utils/app'
import configuration from '@/configuration'
import { loadLanguageAsync, i18n } from '@/plugins/i18n/i18n'
import { ref, reactive, computed, onMounted, defineComponent } from 'vue'
import { useRouter } from 'vue-router/composables'
import store from '@/store'
import { useRecaptcha } from '@/use/recaptcha'
import { useNewsHint } from '@/use/useNewsHint'
import GRecaptchaV2Modal from '@/components/GRecaptchaV2Modal.vue'

export default defineComponent({
  name: 'LoginForm',
  components: {
    OrgSearch,
    GRecaptchaV2Modal,
  },
  props: {
    appTheme: {
      type: String,
      default: '',
    },
  },
  setup (props, { emit }) {
    const router = useRouter()
    const {
      recaptchaVerifyToken,
      v3RecaptchaVerify,
      recaptchaVersionFailed,
      recaptchaVersionUse,
      showV2Recaptcha,
      v2RecaptchaVerify,
      handleRecaptchaError,
    } = useRecaptcha()
    const formRef = ref(null)
    const subdomainCode = ref(null)
    const langs = ref([
      {
        label: '繁體中文',
        value: 'zh',
      },
      {
        label: 'English',
        value: 'en',
      },
      {
        label: '日本語',
        value: 'jp',
      },
      {
        label: '한국어',
        value: 'kr',
      },
    ])
    const authFormData = reactive({
      orgCode: '',
      email: '',
      password: '',
      agreeTerms: false,
    })
    const loading = reactive({
      login: false,
    })
    const orgList = ref([])
    const queryOrg = ref('')
    const selectedOrg = ref(null)
    const isClickTerms = ref(false)

    const selectedLang = computed({
      get: () => i18n.locale,
      set: (value) => {
      // 動態載入新語言
        loadLanguageAsync(value)
          .then(() => {
            console.log(`Language switched to: ${value}`)
          })
          .catch((error) => {
            console.error('Failed to switch language:', error)
          })
      },
    })
    const formRules = computed(() => ({
      email: noEmptyRules(i18n.t('login.email.noEmptyRules.text')),
      // password: noEmptyRules(i18n.t('login.password.placeholder')),
      password: [
        noEmptyRules(i18n.t('login.password.placeholder')),
        // minLengthRules(8, i18n.t('validation.minLength2.text', { number: 8 })),
        // atLeastOneUpperCaseRules(),
        // atLeastOneLowerCaseRules(),
        // atLeastOneNumberRules(),
        // atLeastOneSpecialCharRules(),
      ],
      orgCode: changeEventRules(i18n.t('login.group.changeEventRules.text')),
      agreeTerms: {
        message: i18n.t('login.agree.agreeRules.text'),
        trigger: 'change',
        validator: (rule, value, callback) => {
          if (value) return callback()
          return callback(new Error(i18n.t('login.agree.noEmptyRules.text')))
        },
      },
    }))
    const version = computed(() => getAppVersion())
    const year = computed(() => dayjs().year())
    const copyRightSite = computed(() => {
      let site = 'ohbottech.com'
      if (props.appTheme === 'wishmobile') {
        site = 'wishmobile.com'
      }
      if (props.appTheme === 'xuanien') {
        site = 'Xuanen.com'
      }
      return site
    })
    // => 當改變集團
    const changeOrg = async () => {
      authFormData.orgCode = queryOrg.value.code
      await getOrgsInfoByCode()
    }

    // => 使用 code 取得集團資訊
    const getOrgsInfoByCode = async () => {
      try {
        selectedOrg.value = await GetOrgsByCode({ code: authFormData.orgCode })
        queryOrg.value = selectedOrg.value.name
        emit('change-selected-org', selectedOrg.value)
      } catch (error) {
        console.log(error)
      }
    }

    // => 檢查子網域
    const checkSubDomain = async () => {
      const row = location.host.split('.')
      if (row.length === 4) {
        subdomainCode.value = row[0]
        authFormData.orgCode = subdomainCode.value
        await getOrgsInfoByCode()
        if (!queryOrg.value) return router.push('/not-found')

        return true
      }

      if (configuration('appRuntime') === 'dev') {
        subdomainCode.value = 'devorg'
        authFormData.orgCode = 'devorg'
        await getOrgsInfoByCode()
      }
      return false
    }

    // => 點擊條款
    const handleClickTerms = (event) => {
      emit('showTerms')
      isClickTerms.value = true
    }

    // => 勾選同意條款
    const handleChangeTerms = () => {
      if (isClickTerms.value) {
        isClickTerms.value = false
        return
      }
      authFormData.agreeTerms = !authFormData.agreeTerms
    }

    // => 關閉 reCAPTCHA v2
    const onCloseRecaptchaV2 = () => {
      showV2Recaptcha.value = false
      loading.login = false
    }

    // => 登入
    const handleLogin = async () => {
      if (loading.login) return
      loading.login = true
      if (!(await formUtils.checkForm(formRef.value))) {
        loading.login = false
        return
      }

      if (!recaptchaVersionFailed.v3) {
        await v3RecaptchaVerify('loginByEmail')
      } else {
        if (!recaptchaVerifyToken.value) {
          showV2Recaptcha.value = true
          loading.login = false
          return
        }
      }

      const payload = {
        ...authFormData,
        recaptchaToken: recaptchaVerifyToken.value,
        recaptchaVersion: recaptchaVersionUse.value,
      }

      const [res, err, rawErr] = await EmailLogin(payload)
      loading.login = false
      if (err) {
        if (handleRecaptchaError(rawErr.errorCode)) return
        window.$message.error(err)
        return
      }

      if (res.token) {
        store.commit('auth/SET_TOKEN', res.token)
        const { checkUnreadArticles } = useNewsHint()
        checkUnreadArticles()
      }

      window.$message.success('登入成功!')
      if (ls.getNeedTransfer()) {
        const transferPath = ls.getNeedTransfer()

        ls.removeNeedTransfer()
        router.push(transferPath)
      } else router.push('/').catch(() => {})
    }

    const onForgetPassword = () => {
      router.push({
        name: 'ForgetPassword',
        query: {
          email: authFormData.email,
        },
      })
    }

    onMounted(async () => {
      if (await checkSubDomain()) {
        return
      }
      const code = router.currentRoute.params.orgCode

      if (code) {
        authFormData.orgCode = code
        const org = orgList.value.find((item) => item.code === code)
        queryOrg.value = org?.name || ''
      }
    })
    return {
      formRef,
      iconOrg,
      iconEmail,
      iconPassword,
      subdomainCode,
      authFormData,
      orgList,
      queryOrg,
      selectedOrg,
      isClickTerms,
      formRules,
      version,
      year,
      copyRightSite,
      changeOrg,
      getOrgsInfoByCode,
      checkSubDomain,
      handleLogin,
      handleClickTerms,
      handleChangeTerms,
      showV2Recaptcha,
      v2RecaptchaVerify,
      onCloseRecaptchaV2,
      loading,
      selectedLang,
      langs,
      onForgetPassword,
    }
  },
})
</script>
<style lang="scss" scoped>
h1 {
  @apply mb-[16px] text-white text-[24px] font-medium;
}

.form-layout {
  @apply z-[1] relative flex flex-col justify-center items-center w-6/12;

  .beauty & {
    @apply mr-[120px];
  }

  .ohbot & {
    background: linear-gradient(151.14deg, #0075ff -5.26%, #014eff 83.76%);
  }

  .login-form {
    @apply mb-[16px] pt-[81px] px-[28px] pb-[18px] w-[456px] bg-white rounded-[4px];
    box-shadow: 0 1px 30px rgba(1, 10, 38, 0.1);

    .ohbot & {
      border-radius: 50px 4px;
    }

    .righttime &, .wishmobile &, .xuanien & {
      @apply w-[600px] shadow-none;
    }

    &__login {
      @apply relative text-gray-100;

      &::after {
        .ohbot & {
          content: '';
          @apply absolute top-[calc(50%-9px)] left-[56px] w-[20px] h-[18px];
          background-image: url('../../../assets/login/ohbot/login-icon.png');
        }
      }

      .righttime &, .wishmobile &, .xuanien & {
        @apply mb-[68px] text-center text-primary-100 text-[38px] font-bold tracking-[4px];
      }
    }

  }
  .login-form__agreecheck {
    .agree-terms {
      @apply inline whitespace-normal text-primary-100 cursor-pointer;
    }
    ::v-deep {
      .el-checkbox {
        @apply flex whitespace-normal;
      }
      .el-checkbox__label {
        @apply text-info cursor-default;
      }
    }
  }
  .copyright {
    .beauty & {
      @apply text-[#5E5E5E];
    }

    .ohbot & {
      @apply text-[#FFFFFF];
    }

    .righttime &, .wishmobile &, .xuanien &{
      @apply absolute bottom-[68px];
      @apply text-primary-50;
    }

    .version {
      @apply ml-[20px];
    }
  }

  ::v-deep .el-form-item__content {
    .righttime &, .wishmobile &, .xuanien &  {
      @apply flex justify-center;
    }
  }

  ::v-deep .el-button {
    @apply w-[140px] h-[48px] float-right;

    .ohbot & {
      @apply bg-[#0075FF] border-none rounded-[4px];
      box-shadow: 0px 1px 9px rgba(1, 10, 38, 0.1);
    }

    .righttime &, .wishmobile &, .xuanien &  {
      @apply mt-[52px] w-full max-w-[400px] h-[60px] rounded-[34px] text-[24px];
    }
  }

  ::v-deep .el-form-item__label {
    @apply w-full inline-flex;
    .righttime &, .wishmobile &, .xuanien &  {
      @apply relative text-primary-100 ;
    }

    &::before {
      .righttime &, .wishmobile &, .xuanien &  {
        @apply absolute right-[-16px];
      }
    }
  }

  ::v-deep .el-input__inner {
    .righttime &, .wishmobile &, .xuanien &  {
      @apply pb-[4px] pl-[48px] text-[20px] border-none rounded-none;
      border-bottom: 1px solid var(--primary-100);
    }
  }

  ::v-deep .el-input__prefix {
    .righttime &, .wishmobile &, .xuanien &  {
      @apply hidden;
    }
  }
}

.form__input__org,
.form__input__email,
.form__input__password {
  @apply w-full;
}

.prefix__icon {
  @apply absolute bottom-[8px] left-0 object-contain;
}

@media screen and (max-width: 1023px) {
  .form-layout {
    .ohbot & {
      @apply fixed top-2/4 right-[80px] translate-y-[-50%] bg-none;
    }

    .righttime & {
      @apply w-full;
      background: linear-gradient(172.5deg, #8fd6bf -1.98%, #5fc4a4 101.46%);

      .login-form {
        @apply pt-[40px] pb-[180px] px-[60px] rounded-[20px];
      }
    }
    .wishmobile & {
      @apply w-full;
      background: linear-gradient(172.5deg, #1D60AE -1.98%, #1D60AE 101.46%);

      .login-form {
        @apply pt-[40px] pb-[180px] px-[60px] rounded-[20px];
      }
    }
  }

  .form-layout > .copyright {
    .ohbot & {
      @apply text-[#0075FF];
    }
  }
}

@media screen and (max-width: 1200px) {
  .form-layout {
    .righttime & {
      @apply justify-start w-full min-h-[1194px];
      background: linear-gradient(172.5deg, #8fd6bf -1.98%, #5fc4a4 101.46%);

      .login-form {
        @apply mb-[60px] pt-[40px] pb-[180px] px-[60px] rounded-[20px];
        box-shadow: 0 21px 30px rgba(1, 10, 38, 0.1);
      }
    }

    .wishmobile & {
      @apply justify-start w-full min-h-[1194px];
      background: linear-gradient(172.5deg, #1D60AE -1.98%, #1D60AE 101.46%);

      .login-form {
        @apply mb-[60px] pt-[40px] pb-[180px] px-[60px] rounded-[20px];
        box-shadow: 0 21px 30px rgba(1, 10, 38, 0.1);
      }
    }

    .copyright {
      .righttime &, .wishmobile &  {
        @apply absolute bottom-[16px] left-[24px];
        @apply text-[14px] leading-[16px];
      }
    }
  }
}
::v-deep .locale-changer .el-input {
  @apply w-[120px];
  .el-input__inner {
    @apply border-none;
  }
}
</style>
